.modal-wrapper:global(.d-modal-wrapper) {
  :global(.MuiDialog-paper) {
    top: auto;
    padding: 20px 15px;
    background-color: #efefef;
    margin: auto;
    max-width: 600px;
    width: 90%;
  }
}
