.custom-sort-arrow-normal svg {
  color: gray !important;
}
.custom-sort-arrow-active svg {
  color: rgb(88, 194, 254) !important;
}
/* .MuiTableSortLabel-active {
  color: black !important;
} */

/* MuiSvgIcon-root MuiTableSortLabel-icon  */

.table th span.font-light {
  font-weight: 300 !important;
}
