//@済:日時-左
.__message__revecie {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  font-size: 0.77rem;
  color: #5F5F5F;
  text-align: left;

  > div {
    margin-right: 6px;
  }

  img {
    max-width: 200px;
    border-radius: 16px;
    margin-right: 3px;
  }

  //@済:チャットボックス-左
  .__receive {
    background: #e0e0e0 !important; //チャットボックスカラー
    color: #5F5F5F; //テキスト文字
    font-size: 0.77rem;
    margin: 2px 0px;
    padding: 8px 0px;
    height: unset;
    max-width: 78%;
    text-align: left;
    margin-right: 3px;
  }

  p {
    margin-bottom: 0;
  }
}

//@済:日時-右
.__message__sender {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
  font-size: 0.77rem;
  color: #5F5F5F;
  text-align: right;

  img {
    max-width: 200px;
    border-radius: 16px;
  }

  //@済:チャットボックス-右
  .__sender {
    font-size: 0.77rem;
    margin: 2px 0px;
    padding: 8px 0px;
    height: unset;
    max-width: 78%;
    text-align: left;
    background-color: #CCE3FF; //チャットボックスカラー
    color: #5F5F5F; //テキスト文字
  }

  > p {
    margin-bottom: 0;
    margin-right: 6px;
  }
}
