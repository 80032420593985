.profile-modal table {
  margin-bottom: 20px;

  td {
    font-size: 1rem !important;
    width: auto;
  }
}

#copy-btn {
  margin-left: 6px;
  cursor: pointer;
}

.wrapper-tag {
  .tag {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 3px 5px;
    text-align: center;
    align-items: center;
    display: inline-block;
    margin: 6px 12px 0 0;
  }
}

.d-select-multiple-cus {
  &:hover:not(.Mui-disabled):before,
  &:before {
    border-bottom: transparent !important;
  }

  .MuiSelect-select {
    padding-left: 8px;
  }
}

@media (max-width: 1900px) {
  .d-box-content-action-user .btn-custom {
    margin-bottom: 0.5em !important;
  }
}

@media (max-width: 1200px) {
  .d-box-content-action-user-short .btn-custom {
    margin-bottom: 0.5em !important;
  }
}
