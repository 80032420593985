@font-face {
  font-family: 'myFont';
  src: url('./assets/fonts/GenEiAntiquePv5-M.ttf');
}

body {
  margin: 0;
  font-family: 'ヒラギノ角ゴ ProN', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.a-text-decoration:hover,
.a-text-decoration {
  text-decoration: unset;
}

.MuiDialog-container .MuiDialog-paper {
  position: fixed;
  top: 10%;
  max-height: 80%;
}

.btn-waiting-like {
  background-color: #339966 !important;
  color: #ffffff !important;
  cursor: pointer !important;
}

.sidenav {
  overflow-y: auto;
}

.form__search .col-form-label {
  font-size: 14px !important;
  padding: 5px !important;
}

.form__search .radio-option span {
  font-size: 12px !important;
}

.blockSearch {
  background-color: white;
  border-radius: 5px;
  padding: 10px 0px 15px 0px;
}

.input-rank {
  padding-top: 15px;
  padding-bottom: 10px;
  border-radius: 5px;
  height: 40px;
}

.table {
  font-size: 0.875rem !important;
}

.MuiCard-root {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px -6px,
  rgba(236, 239, 244, 0) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px !important;
}

.MuiPaper-rounded {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.table thead th {
  vertical-align: middle !important;
  font-weight: 300 !important;
  padding: 0.6rem;
}

.align {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
}

.align-fixed-top {
  position: fixed;
  right: 30px;
  z-index: 999;
  width: 100%;
  transform: translateX(16px);
  top: 52px;
  padding: 12px 22px 12px 0;
}

.main-content-custom {
  margin-top: 61px !important;
}

.m-top-10 {
  margin-top: 10px;
}

.btn-custom {
  padding: 6px 8px !important;
  margin: 0;
}

.btn-cus-font {
  font-size: 10px !important;
}

.btn-cus-r5 {
  margin-right: 5px !important;
}

.btn-cus-l5 {
  margin-left: 5px !important;
}

.btn-custom.mr-3 {
  margin-right: 1rem !important;
}

.btn-custom.btn-red {
  margin-right: 10px !important;
}

.form__search .btn-search {
  padding: 12px 45px !important;
  font-size: 18px;
  font-weight: 800;
  color: white;
}

.mtSearch {
  margin-top: 15px;
}

.imgRound {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.imgSquare {
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 20% !important;
  -webkit-border-radius: 20% !important;
  -moz-border-radius: 20% !important;
  -ms-border-radius: 20% !important;
  -o-border-radius: 20% !important;
}

.MuiButtonBase-root.MuiFab-root {
  transform: scale(0.8);
}

.MuiButtonBase-root.MuiFab-root span {
  font-size: 15px;
}

.MuiTableRow-root:first-child td {
  border-top: none;
}

.scroll-x-on-top {
  transform: rotateX(180deg) !important;
  -webkit-transform: rotateX(180deg) !important;
  -moz-transform: rotateX(180deg) !important;
  -ms-transform: rotateX(180deg) !important;
  -o-transform: rotateX(180deg) !important;
  overflow-x: auto;
}

.scroll-x-on-top > table {
  transform: rotateX(180deg) !important;
  -webkit-transform: rotateX(180deg) !important;
  -moz-transform: rotateX(180deg) !important;
  -ms-transform: rotateX(180deg) !important;
  -o-transform: rotateX(180deg) !important;
}

.contact-badge {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  padding-top: 1.3px;
  background-color: #c74545;
  top: -8px;
  right: -9px;
  font-weight: bold;
  font-size: 12px;
}

.message-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  padding-top: 1.3px;
  background-color: #c74545;
  top: 4px;
  right: 7px;
  font-weight: bold;
  font-size: 12px;
}

.object-fit-cover {
  object-fit: cover;
}

.d-main-image .image-watermark img {
  width: 70px;
  height: 70px;
}

.sub-images .image-watermark img {
  width: 30px;
  height: 30px;
}


/** Modal drag and drop file */
.form-file-upload {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: unset;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.d-box-radio-group {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: calc(0.275rem + 1px);
  padding-bottom: calc(0.275rem + 1px);
}

#d-hover-tooltip {
  position: absolute;
  top: -9999px;
  left: -50%;
  z-index: 9999;
  max-width: 550px;
  background-color: #f7fbff;
  color: #212121;
  text-align: left;
  padding: 10px 12px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.sub-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
}

.sub-images > div {
  position: relative;
  float: left;
  margin-left: 15px;
  margin-bottom: 15px;
}

.sub-images > div > label {
  margin-bottom: 0
}

.d-main-image,
.sub-images > div {
  border: 1px solid rgb(244, 234, 234);
}

.d-justify-space-between {
  justify-content: space-between !important;
}

.d-justify-center {
  justify-content: center !important;
}

.d-justify-start {
  justify-content: flex-start !important;
}

.d-line-content:after {
  content: '';
  background-color: rgba(0, 0, 0, .125);
  border-top: 1px solid rgba(0, 0, 0, .1);
  margin: 0;
  position: absolute;
  top: 18px;
  height: 1px;
  width: calc(100% - 30px);
}

/*Css search form　#重要検索ボックス*/
.d-item-form-wrapper .radio-group.d-cus-item {
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px 20px;
}

.d-item-form-wrapper .d-cus-item .d-cus-item-child {
  display: flex;
  flex-grow: 0 !important;
  margin-bottom: 0;
  min-width: 102px;
  justify-content: flex-start;
  width: auto !important;
}

.d-col-2-symbol {
  margin: 0;
  max-width: 30px !important;
}

.d-width-no {
  width: 5% !important;
}

.d-width-phone {
  width: 10% !important;
}

.ellipsis-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.d-wrapper-tag-flex-grid-3 {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  row-gap: 1px;
}

.d-wrapper-tag-flex-grid-4 {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  row-gap: 1px;
}

.d-wrapper-tag-flex-wrap {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiDialogContent-root {
  padding-bottom: 22px !important;
  margin-bottom: 0;
}

.d-wrapper-custom-select .d-svg-icon-select {
  align-self: center;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.text-capitalize {
  text-transform: capitalize;
}
