.tableNameProfile {
  th {
    height: 0px;
  }
}

.btnEditProfile {
  padding: 3px 14px !important;
  color: white !important;
  margin-right: 20px !important;
}

.btnEditProfile:focus {
  outline: none !important;
}

.btnEditRootProfile {
  padding: 6px 14px !important;
  background-color: rgb(88, 194, 254) !important;
  color: white !important;
  margin-right: 20px !important;
}

.btnEditRootProfile:focus {
  outline: none !important;
}

.NameProfileList {
  display: flex;
  justify-content: flex-end;
  margin-right: 100px;
  margin-bottom: 50px;
  .MuiTableCell-paddingCheckbox {
    padding: 5px 20px 5px 4px;
  }
}

.m-auto.ml-0 {
  margin-left: 0px !important;
}
