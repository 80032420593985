.datePicker {
  display: flex;
}

.react-datepicker-wrapper {
  width: 40%;
}

.hour {
  width: 20%;
  margin-left: 10px;
}

.minus {
  width: 20%;
  margin-left: 10px;
}
