.operatorList {
  margin-bottom: 20px;
  width: 40% !important;
}

.container-conversation {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #666;
  }

  ::-webkit-scrollbar-track {
    background: #ddd;
  }
}

.list-conversation {
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  min-height: 70vh;
  max-height: 90vh;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  .item-head {
    background: #FFFFFF !important;
    border-bottom: 1px solid #DFDFDF !important;
    text-align: center;

    li {
      height: inherit;
    }
  }

  .user {
    display: flex;
    align-items: center;
    width: 30%;

    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0.5rem;
      @media only screen and (max-width: 992px) {
        height: 30px;
        width: 30px;
      }
    }

    .user-other,
    .MuiTypography-root {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @media only screen and (max-width: 992px) {
      .MuiTypography-root {
        font-size: 13px;
      }
    }
  }

  .Mui-selected {
    border-radius: 10px;
    min-width: 0;
    padding-left: 25px;
  }

  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.d-wrapper-conversation .conversation-content,
.container-conversation .list-conversation {
  height: calc(100vh - 104px);
}

.d-wrapper-conversation .conversation-content {
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.d-wrapper-conversation .timebar {
  height: unset !important;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.chatFrame {
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1.2rem;
  margin-bottom: 10px;
}

.shrink {
  height: calc(70% - 60px);
}

.userMessageWrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  width: 100%;
}

.messageFrame {
  width: 60%;
  height: fit-content;
  padding: 1rem;
  border-radius: 4px;
  margin: 0 0.5rem;
  overflow-wrap: break-word;
}

.timeSpan {
  font-size: 0.7rem;
  align-self: flex-end;
}

.myMessageWrapper {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  width: 100%;
}

.wrapperSendMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  border-bottom: 0;

  :focus {
    outline: 0;
  }

  textarea {
    padding: 3px;
  }
}

.textArea {
  width: 100%;
  resize: none;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;

  .MuiAvatar-root {
    position: unset !important;
  }
}

.btnCustomer {
  margin-right: 10px !important;
}

.iconCustomer {
  cursor: pointer;
}

.attach-icon {
  cursor: pointer;
}

.wrapperTopMessage {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;

  button {
    height: 30px;
  }
}

.textCenter {
  text-align: center;
  margin: 0.5rem;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 0.5px;
    width: 100%;
    background: #b1b1b1;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  > span {
    background: #fff;
    padding: 0 10px;
    position: relative;
  }
}

.contentImage {
  max-width: 600px;
  max-height: 400px;
  margin: 0 0.5rem;
  object-fit: cover;
  max-width: 200px;
  border-radius: 16px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.chatControlFrame {
  padding: 0 2rem;
  box-sizing: border-box;
  position: relative !important;

  .imagePreview {
    position: relative;
    width: fit-content;

    .clearDiv {
      position: absolute;
      right: 0;
      top: 0;
      background-color: #000;
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      color: #fff;
      width: 16px;
      height: 16px;
      transition: opacity 0.1s;
      display: flex;
    }
  }

  img {
    width: 145px !important;
    height: 145px !important;
    object-fit: contain;
    box-sizing: content-box;
    padding: 0.5rem;
  }

  textarea {
    border: 0;
  }
}

.imageMessage {
  position: relative;

  .clearButton {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #000;
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  .MuiSvgIcon-root {
    color: #fff;
    height: 22px;
    width: 22px;
    transition: opacity 150ms ease-out;
  }

  &:hover {
    .MuiSvgIcon-root {
      display: flex;
    }
  }
}

.timer-container {
  margin: 10px 0 20px 0;
}
