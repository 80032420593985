.d-box-disable {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.d-label-disable {
  color: rgba(0, 0, 0, 0.38);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.d-box-disable .d-text-disable {
  color: currentColor;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
