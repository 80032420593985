$body: #E5F1FF; // 1:全体背景色
$black: #070919;
$white: #fff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-900: #3b3e66;
$ty-line: #DFDFDF; // 一覧表示ライン色
$bg-tr-hover: #CCE3FF; // 一覧表示背景色（選択時）
$bg-tb: #FFFFFF; // 一覧表示行背景色
$bg-btn: #4796EF; // ボタン色（詳細ページ上部、検索、登録、編集。タブ選択、Modal画面のタブ、ログインボタン）
$btn-red: #FF3333; // クリアボタン色（クリア、閉じる、削除）
$radio-color: #538ED1; // チェックボタン色（ラジオ、チェック、一覧ページのページ数）
$color-title-modal: #4F4F4F; // Modalポップアップタイトル文字色、Modal対象者ニックネーム、チャットルーム画面ニックネーム
$color-link: #0077FF; // 一覧表示の表示編集テキストリンク
$color-border-checkbox: #005AC1; // チェックボックス枠色

$spacer: 1rem;

$list-group-border-color: rgba($black, 0.125);
$border-color: $gray-300;

$transition-base: all 0.2s ease-in-out;

$font-size-base: 0.95rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$font-weight-bold: 700;

$table-head-bg-1: rgba($gray-300, 0.7);
$table-dark-color: $white;
$table-dark-bg: $gray-900;
$table-dark-border-color: darken($gray-900, 10%);
$table-head-color: $gray-900;
$table-head-bg: desaturate($table-head-bg-1, 5%);
$table-border-color: darken($border-color, 5%);

$card-spacer-x: 1.25rem;

$box-shadow-layout-bottom: 0 0.46875rem 2.1875rem rgba($gray-900, 0.03),
0 0.9375rem 1.40625rem rgba($gray-900, 0.03),
0 0.25rem 0.53125rem rgba($gray-900, 0.05),
0 0.125rem 0.1875rem rgba($gray-900, 0.03);

$height-nav-top: 64px;
$width-nav-left: 64px;

// ----------------------------------------
body {
  background-color: $body !important;
}

.font-size-lg {
  font-size: $font-size-lg;
}

table {
  border-collapse: unset !important;
  border-spacing: 0 !important;
}

/*Modal画面 行の高さ*/
.table-modal-wrapper table {
  .MuiTableCell-body {
    height: 40px;
  }

  td,
  th {
    padding: 0 0.65rem;
  }
}

.table {
  width: 100%;
  color: $gray-900 !important;
  margin-bottom: unset !important;

  th {
    font-size: 0.88rem !important;
    font-weight: normal;
    border-top: none !important;
    border-bottom: none !important;

    &:first-child {
    }
  }

  tr {
    border-bottom: none !important;

    td:first-child {
      // text-align: center;
    }
  }

  thead {
    tr {
      th span {
        color: $gray-900 !important;
      }
    }
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

// Alignment

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-black-50 {
  color: rgba($black, 0.5) !important;
}

.align-box-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// Height

.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

// Width

.w-auto {
  width: auto;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-43 {
  width: 43%;
}

.w-50 {
  min-width: 50%;
}

.max-w-70 {
  max-width: 70%;
}

.text-black {
  color: $black;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.MuiTableCell-head {
  white-space: nowrap;
}

.MuiTableCell-stickyHeader {
  background-color: #5cc897 !important;
  padding: 20px;
}

.d-color-link,
.d-color-link > a,
.iconCustomer,
.pagination a,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: $color-link !important;
}

.data-column {
  min-width: 350px;
}

.button-column {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.main-page {
  margin-left: $width-nav-left;
  transition: margin-left 0.2s;

  .space-top {
    position: absolute;
    top: $height-nav-top;
    left: 0;
    right: 0;
    background: rgb(236, 239, 244);
  }

  .space-left {
    position: absolute;
    top: $height-nav-top;
    bottom: 0;
    left: $width-nav-left;
    background: rgb(236, 239, 244);
    width: 20px;
  }
}

.main-content {
  padding: 20px;
  padding-top: 0;
  overflow-y: auto;
  max-height: calc(100vh - 65px);
}

.page-title {
  margin-bottom: 30px;
  padding-left: 20px;
}

/*左メニュー背景色*/
.sidenav2 {
  position: fixed;
  top: 0;
  z-index: 1250 !important;

  &-expanded + .main-page {
    margin-left: 280px;

    .space-top {
      position: absolute;
      top: $height-nav-top;
      left: 0;
      right: 0;
      background: rgb(236, 239, 244);
    }

    .space-left {
      position: absolute;
      top: $height-nav-top;
      bottom: 0;
      left: 280px;
      background: rgb(236, 239, 244);
      width: 20px;
    }
  }

  &-expanded {
    overflow-y: auto;
    overflow-x: hidden;
    width: 280px !important;

    .sidenav---sidenav-subnav---1EN61 {
      padding-left: 50px;
    }

    .sidenav---navitem---9uL5T {
      padding: 0 14px 0 16px !important;
      white-space: nowrap;
    }
  }

  &-collapsed {
    svg {
      display: none;
    }
  }

  .sidenav---navitem---9uL5T {
    svg {
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }

  .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL {
    svg {
      transform: rotate(180deg);
    }
  }
}

.radio-group {
  width: 100%;
  display: flex;
  align-items: center;
}

input,
select {
  width: 100%;
  height: 30px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 21px;
  padding-left: 10px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + span,
[type='radio']:not(:checked) + span {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: 14px;
}

[type='radio']:checked + span:before,
[type='radio']:not(:checked) + span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #ddd;
  border-radius: 100%;
  background: #e0e0e0;
}

[type='radio']:checked + span:after,
[type='radio']:not(:checked) + span:after {
  content: '';
  width: 18px;
  height: 18px;
  background: #58c2fe;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:checked + span:after,
[type='radio']:not(:checked) + span:after {
  background: $radio-color;
}

[type='radio']:not(:checked) + span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.d-form-login-action .d-label-control-svg svg path {
  fill: currentColor !important;
  color: $radio-color !important;;
}

input[type=checkbox]:checked {
  accent-color: $radio-color !important;
}

.d-item-checkbox .d-cus-item-child {
  position: relative;
}

.d-item-checkbox input[type='checkbox']:not(:checked) + span:before {
  border: 2px solid $color-border-checkbox;
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.d-item-checkbox input[type=checkbox]:not(:checked) {
  outline: none;
  background:transparent;
  border:0;
  -webkit-appearance: none;
}

.d-checkbox .d-checkbox-ui,
.tag .d-checkbox-ui {
  color: $color-border-checkbox;
}

thead {
  background-color: #5cc897;
}

.image-watermark {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #00000099;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.form-group {
  margin-bottom: 1rem !important;
}

.pagination {
  display: flex;
  justify-content: center;

  a {
    padding: 8px;
  }

  li.active {
    a {
      color: #333;
      pointer-events: none;
    }
  }
}

.card-box {
  border-width: 1px;

  .card-footer,
  .card-header {
    border-width: 1px;
    border-color: $list-group-border-color;
  }

  .card-header {
    display: flex;
    align-items: center;
  }

  .card-header--title {
    flex-grow: 1;

    & > b {
      display: block;
    }

    & > small {
      text-transform: uppercase;
      display: block;
      opacity: 0.3;
      font-weight: bold;

      & + b {
        padding-top: ($spacer / 4);
      }
    }
  }

  &.card-box-border-bottom {
    border-top-color: $list-group-border-color !important;
    border-right-color: $list-group-border-color !important;
    border-left-color: $list-group-border-color !important;
    border-bottom-width: 4px;
    border-style: solid;
  }
}

.card-hover-indicator {
  opacity: 0.2;
  transition: $transition-base;
}

.card.card-box:hover {
  .card-hover-indicator {
    opacity: 0.8;
    transform: scale(1.3);
  }
}

.card {
  & > .bg-composed-wrapper {
    top: -1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -2px;
    z-index: 6;
  }
}

.card-box.mb-4,
a.card.mb-4,
.MuiCard-root.mb-4 {
  margin-bottom: 16px !important;
}

.mb-4-spacing {
  margin-bottom: 32px !important;
}

//  Core table
.table {
  font-size: $font-size-base;

  thead th {
    text-transform: uppercase;
    background: $gray-200;
    font-size: $font-size-sm;
  }

  tr {
    transition: $transition-base;
  }

  td,
  th {
    vertical-align: middle;
  }

  &.table-alternate {
    thead th {
      text-transform: none;
      background: transparent;
      font-size: $font-size-base * 1.1;
      font-weight: normal;
      color: $gray-900;
    }
  }
}

.table thead th,
.table tr,
.list-conversation .item-head {
  height: 48px;
}

.table {
  .thead-dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .thead-light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.card-body-avatar {
  position: relative;
  padding-top: calc(#{$card-spacer-x} + 60px);

  & > .avatar-icon-wrapper {
    margin: 0;
    position: absolute;
    height: 120px;
    width: 120px;
    top: -60px;
    z-index: 3;

    .avatar-icon {
      height: 100%;
      width: 100%;
      box-shadow: $box-shadow-layout-bottom;
    }

    img {
      max-width: 100%;
      height: auto;
      width: 100%;
    }

    &--sm {
      height: 60px;
      width: 60px;
      top: -30px;
    }
  }

  &.text-center {
    & > .avatar-icon-wrapper {
      left: 50%;
      margin-left: -60px;

      .avatar-icon {
        margin: 0;
      }

      &--sm {
        margin-left: -30px;
      }
    }
  }
}

//  Core

.avatar-icon-wrapper {
  display: inline-block;
  margin-right: ($spacer / 4);
  position: relative;
  font-size: 1.2rem;

  .badge {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
  height: 20px;
  line-height: 21px;
}

.content-center {
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.pagination {
  li.active {
    a {
      color: #333 !important;
      pointer-events: none;
    }
  }
}

.align-top {
  margin-top: 10px !important;
}

.MuiPaper-root::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  background-color: #f5f5f5;
}

.MuiPaper-root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #171f46;
}

.MuiPaper-root::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.MuiPaper-root::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.enrollmentSelect {
  .MuiInputBase-input {
    padding: 18px 22px 10px !important;
  }
}

.loginForm {
  display: block;
  width: 40%;
  padding: 16px 24px 24px;
  margin: 120px auto;
  background-color: white;
  box-shadow: 0px 0px 5px gray;
  position: relative;
}

.text-red {
  color: red;
}

.no-highlight .sidenav---navitem---9uL5T {
  cursor: default !important;
}

.no-highlight:hover {
  .sidenav---navitem---9uL5T::after {
    opacity: 0 !important;
  }

  .sidenav-text-root,
  .sidenav-text-root span {
    color: #FFFFFF !important; /*@済:左メニューテキスト色*/
  }
}

.sidenav-text-root > span,
.sidenav-text-root {
  color: #FFFFFF !important; /*@済:左メニューテキスト色*/
}

.none-tag {
  color: white !important;
}

.none-tag:hover {
  text-decoration: none !important;
}

.table td,
.table th {
  border-top: 1px solid $ty-line !important;
}

.table tr,
.MuiTableRow-root {
  background: $bg-tb !important;
}

.table tr:hover,
.MuiTableRow-root:hover {
  background-color: $bg-tr-hover !important;
}

.MuiFab-primary:hover {
  background-color: #135194;
}

.MuiFab-primary {
  background-color: $radio-color !important;
}

.btnEditProfile,
.btnEditRootProfile,
.async-blue.Mui-selected,
.btnReportDatabase,
.btnCustomer,
.d-form-login-action .btn-submit,
.form__search .btn-search {
  background-color: $bg-btn !important;
}

.btnDeleteDatabase {
  background-color: $btn-red !important
}

.MuiTabs-root .Mui-selected {
  color: white !important;
}

.list-conversation .item-head {
  border-bottom: 1px solid $ty-line !important;
}

/*@済:詳細画面-上部ボタ文字色*/
.btn-blue, .btn-red {
  color: #FFF !important;
}

/*サブボタン*/
.btn-blue {
  background-color: $bg-btn !important;
}

.d-blue-color {
  color: $bg-btn;
}

/*削除ボタン*/
.btn-red {
  background-color: $btn-red !important;
}

.d-red-color {
  color: $btn-red
}

.align-fixed-top {
  background-color: $body;
}

/*上部バー背景色？*/
.MuiAppBar-colorPrimary {
  background-color: #f305ff !important; // 濃いピンク  #5E4430
}

/*ポップアップ背景色？*/
.MuiDialog-paper {
  background-color: #86dbf7 !important; // 水色  #AE7B3E
}

/*@済:詳細画面-タブ文字色*/
.d-paginate .MuiIconButton-root,
.d-paginate .MuiIconButton-root.Mui-disabled {
  color: #FFFFFF !important;
}

/*タブメニューボタン？*/
.MuiTab-textColorInherit.Mui-selected {
  background-color: rgb(255, 251, 5); // 黄色  163, 0, 0
  color: black;
}

.d-tab-wrapper {
  button {
    border-right: 3px solid rgb(88, 194, 254);
    border-top: 3px solid rgb(88, 194, 254);
    border-bottom: 3px solid rgb(88, 194, 254);
    color: rgba(0, 0, 0, 0.54);

    &:first-child {
      border-left: 3px solid rgb(88, 194, 254);
    }

    &.Mui-selected {
      background-color: #4796EF;

      span {
        color: white;
      }
    }
  }
}

.d-form-login-action {
  .Mui-required {
    .MuiInputLabel-asterisk {
      color: $btn-red;
    }
  }
}

.d-modal-wrapper .d-title-modal-delete {
  padding: 10px 12px 0 24px;
}

.d-wrapper-conversation .d-head-chat .d-title span,
  /*Modalポップアップ色（d-modal-wrapperまで）18(0, 0, 0, 0.26)white12*/
.d-modal-wrapper .d-title-modal-custom .wrapper-info-title,
.d-modal-wrapper .d-title-modal-custom p.d-title,
.d-modal-wrapper .d-title-modal-custom h2 {
  color: $color-title-modal;
  font-size: 18px; // Modalポップアップページタイトル文字サイズ
}

.d-modal-wrapper .d-title-modal-custom h2.MuiTypography-h6 {
  padding-left: 0;
}

// Icon disable
.d-modal-wrapper .d-page-wrapper .MuiButtonBase-root.Mui-disabled svg {
  color: rgba(14, 31, 128, 0.7); // Modalポップアップページ送りSVG色
  background-color: transparent;
}

// Page current
.d-modal-wrapper .d-page-wrapper .MuiFab-root.MuiFab-primary {
  color: white; // Modalポップアップページ送り数字色
  background-color: $radio-color;
}

// Page show background
.d-modal-wrapper .d-page-wrapper .MuiFab-root {
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
}

// Icon show
.d-modal-wrapper .d-page-wrapper .MuiButtonBase-root svg {
  color: #303f9f;
  background-color: transparent;
}

.d-modal-wrapper .d-page-wrapper button.MuiIconButton-root {
  padding: 12px;
}

.d-wrapper-conversation-work .conversation-content,
.d-wrapper-conversation .container-conversation,
.d-modal-wrapper .MuiDialog-paper {
  background-color: #EFEFEF !important; // @済:Modalポップアップページ背景色
}

.d-modal-wrapper .d-title-info-box {
  padding: 0 24px;
  margin-left: 12px;
}

.d-modal-wrapper .d-title-info-box .text {
  color: #7c7b7b;
  font-size: 16px;
}

.d-modal-wrapper .d-title-info-box .text p {
  margin-bottom: 0;
}

.d-modal-wrapper .d-page-wrapper {
  margin-top: 0 !important;
  margin-bottom: -12px !important;
}

.d-border-bottom-form {
  border-bottom: 1px solid #e0e0e0 !important;
}

.d-line-select-border {
  border: 1px solid #e0e0e0 !important;
}

.d-wrapper-conversation-work .container-conversation .conversation-content .d-content-chat,
.d-wrapper-conversation .container-conversation .conversation-content .d-content-chat {
  border-radius: 5px 5px 0 0;
}

.d-wrapper-conversation-work .container-conversation .conversation-content .d-content-action,
.d-wrapper-conversation .container-conversation .conversation-content .d-content-action {
  border-radius: 0 0 5px 5px;
}

.d-wrapper-conversation-work .container-conversation .conversation-content .d-content-chat,
.d-wrapper-conversation-work .container-conversation .conversation-content .d-content-action,
.d-wrapper-conversation .container-conversation .conversation-content .d-content-chat,
.d-wrapper-conversation .container-conversation .conversation-content .d-content-action {
  margin: 0 12px 12px;
  background-color: white;
}

.d-wrapper-conversation-work .container-conversation .conversation-content .d-content-chat,
.d-wrapper-conversation .container-conversation .conversation-content .d-content-chat {
  margin-bottom: 0;
}


/*@済:チャットルーム左側*/
.d-wrapper-conversation-work .container-conversation .conversation-content .d-wrapper-left .messageFrame,
.d-wrapper-conversation .container-conversation .conversation-content .d-wrapper-left .messageFrame {
  border: 1px solid #999999; // 外枠
  background-color: #e0e0e0; // 背景
  color: #5F5F5F; // 文字
}

/*@済:チャットルーム右側*/
.d-wrapper-conversation-work .container-conversation .conversation-content .d-wrapper-right .messageFrame,
.d-wrapper-conversation .container-conversation .conversation-content .d-wrapper-right .messageFrame {
  border: 1px solid #999999; // 外枠
  background-color: #CCE3FF; // 背景
  color: #5F5F5F; // 文字
}

.display-setting-timer {
  flex-grow: 1;
}

.display-setting-timer.d-un-width .radio-group div.-child {
  width: unset !important;
}

.display-setting-timer .radio-group > div:not(:first-child) {
  margin-left: 30px;
}

.display-setting-timer .radio-group label {
  margin-bottom: 0;
}

.list-conversation .item-head,
.list-conversation .item-content,
.text-muted.MuiTableCell-paddingCheckbox,
.MuiTableCell-paddingCheckbox > span,
.MuiTableCell-paddingCheckbox,
.MuiTableCell-paddingCheckbox > time,
.MuiTableCell-body,
.MuiTableCell-body > span,
.color-900,
.timer-container,
.timer-container > span,
.display-setting-timer .radio-group label {
  color: $gray-900 !important;
}

.list-conversation .item-head,
.list-conversation .item-content,
.MuiTableCell-paddingCheckbox > span,
.MuiTableCell-paddingCheckbox {
  font-size: 15px !important;
}

// Nav
.sidenav2-collapsed .sidenav-sub-nav .d-sidenav-sub-nav {
  min-width: 220px;
  background: var(--nav-variable);
}

.sidenav2-collapsed .sidenav-sub-nav .d-sidenav-sub-nav > div:first-child {
  color: white;
  border-bottom: 1px #ccc solid;
}

.sidenav2-collapsed .sidenav-toggle,
.sidenav2-collapsed .sidenav-nav {
  background-color: var(--nav-variable);
}

.sidenav2-collapsed .sidenav-sub-nav .d-sidenav-sub-nav div.d-sidenav-sub-nav-item:hover {
  background-color: var(--nav-variable);
  opacity: 0.8;
  background: rgba(0, 0, 0, .5);
}

.sidenav2-collapsed .svg-logout,
.sidenav2-collapsed .sidenav-logout {
  display: block;
}

.sidenav2-expanded .svg-logout,
.sidenav2-collapsed .sidenav-logout .btn-custom {
  display: none;
}

.sidenav2-collapsed .d-sidenav-logout-nav {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sidenav2-collapsed .d-sidenav-logout-nav svg {
  position: unset;
}

.sidenav2-collapsed > div.sidenav-nav > div {
  .d-sidenav-sub-nav {
    max-height: 280px;
    overflow-y: auto;
    z-index: 9999;
  }

  &:hover > .d-sidenav-sub-nav {
    display: block;
  }
}

.sidenav2-collapsed > div.sidenav-nav > div .d-sidenav-sub-nav::-webkit-scrollbar,
.sidenav2-collapsed div.sidenav-nav::-webkit-scrollbar,
.sidenav2-collapsed div.sidenav-nav:hover::-webkit-scrollbar {
  width: 0px !important;
  height: 5px !important;
}

.sidenav2-collapsed > div.sidenav-nav > div .d-sidenav-sub-nav::-webkit-scrollbar {
  width: 5px !important;
}

.sidenav2-collapsed > div.sidenav-nav > div .d-sidenav-sub-nav::-webkit-scrollbar-thumb,
.sidenav2-collapsed div.sidenav-nav::-webkit-scrollbar-thumb {
  background: 0 0 !important;
  border-radius: 10px !important;
  transition: all .2s;
  visibility: hidden;
}

.sidenav2-collapsed > div.sidenav-nav > div .d-sidenav-sub-nav:hover::-webkit-scrollbar-thumb,
.sidenav2-collapsed div.sidenav-nav:hover::-webkit-scrollbar-thumb {
  background: #aaa !important;
  visibility: visible;
  transition: background .2s;
}

.sidenav2-collapsed > div.sidenav-nav > div .d-sidenav-sub-nav:hover::-webkit-scrollbar-track,
.sidenav2-collapsed div.sidenav-nav:hover::-webkit-scrollbar-track {
  background: #f2f3f5 !important;
  visibility: visible;
}


.d-wrapper-upload .d-mui-paper {
  max-width: 560px;
  margin: 0 auto;
  border-radius: 20px;
}

.d-wrapper-upload .d-content-upload {
  padding-top: 15px;
  background-color: white;
}

.d-wrapper-upload .d-head-upload .d-title {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #efefef;
  height: 45px;
  align-items: center;
  color: #7d7d7d;
  padding: 16px 0;
  box-sizing: border-box;
  font-size: 18px;
}

.d-wrapper-upload .d-head-upload .d-title > div {
  text-align: center;
}

.d-wrapper-upload .d-head-upload .d-clear-icon {
  position: absolute;
  top: 10px;
  right: 16px;
  display: flex;
  justify-content: flex-end;
}
.wrapper-content-cropper-modal {
  background-color: white;
  height: 500px;
}

.wrapper-cropprer-modal {
  .table tr:hover,
  .MuiTableRow-root:hover {
    background-color: white !important;
  }
}

.toast-new-line {
  white-space: pre-line;
}

.d-blue-active {
  background-color: $bg-btn;
  color: white;
}

.react-datepicker__day--outside-month {
  color: gray !important;
  pointer-events: none;
}
