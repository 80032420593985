.inputPointRate {
  background-color: transparent;
  padding: 0;
  padding-left: 2;
  border: none;
  outline: none;
}

.btnEdit {
  width: 19px;
  height: 18px;
}

.pointer {
  cursor: pointer;
}

.editCard {
  .row {
    align-items: center;
  }
}
