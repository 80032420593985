#wrapper-link-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-switch {
    cursor: pointer;

    input[type='radio'] {
      all: unset !important;
    }

    a {
      color: #000000DE;
      text-decoration: none;
    }
  }
}

.group {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}

.api-info {
  width: 100%;
  text-align: end;
  font-size: 12px;
  margin-top: 8px;
  padding: 0 3px
}

.attention {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 50px;
  color: #fff;
}

.wrapper-login-sys {
  height: 100vh;
  background-image: url('../../assets//image/admin_login_sys.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper-login-ope {
  height: 100vh;
  background-image: url('../../assets//image/admin_login_ope.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper-login-agent {
  height: 100vh;
  background-image: url('../../assets//image/admin_login_agent.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper-login-admin {
  height: 100vh;
  background-image: url('../../assets//image/admin_login_admin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}