.linkStyle,
.linkStyle:hover {
  text-decoration: none;
  color: #000;
}

.searchResult {
  margin: 1rem 0;
}

.btnReportDatabase,
.btnDeleteDatabase
{
  padding: 6px 14px !important;
  color: white !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  font-size: 10px !important;
  text-transform: initial !important;
  width: 30px;
}

.btnReportDatabase:focus,
.btnDeleteDatabase:focus
{
  outline: none !important;
}
