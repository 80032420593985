.box-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.d-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0
}
