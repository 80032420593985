#wrapperTag {
  .tag {
    display: flex;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 3px 5px;
    text-align: center;
    align-items: center;
    display: inline-block;
    margin: 6px 12px 0 0;
  }
}
