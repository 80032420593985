input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.col-date {
  flex: 0 0 47.5%;
  max-width: 47.5% !important;
}

.blockSearch {
  .mt-0 {
    margin-top: 0px !important;
  }
}

.table td,
.table th {
  border-bottom: 1px !important;
  vertical-align: middle !important;
}

.card-box .card-footer,
.card-box .card-header {
  border-width: 0 !important;
}
.bottom-back {
  button {
    margin: 20px 0 !important;
  }
}
