.labelTitle:global(.MuiFormLabel-root) {
  font-size: 18px !important;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

:global(.ant-picker-range),
:global(.ant-picker) {
  border-color: #949494;
  & > :global(.ant-picker-input) {
    & > input {
      &::placeholder {
        font-family:
          'Roboto' 'Helvetica',
          'Arial',
          sans-serif;
        color: rgba($color: #000, $alpha: 0.87);
      }
    }
  }
  :global(.ant-picker-suffix) {
    span {
      font-size: 18px;
      color: #6e6e6e;
    }
  }
}
:global(.anticon) {
  color: #6e6e6e;
}

:global(.ant-picker-body) {
  :global(.ant-picker-content) {
    thead {
      background-color: #1677ff;
      tr > th {
        color: #fff;
        font-weight: 500;
      }
    }
  }
}
