.__table__row {
  height: 40;
  label {
    margin: auto;
    padding: 0px;
    display: flex;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .__table__cell {
    width: 80%;
    padding: 1px 0px 0px;

    .__textfield {
      width: 100%;
      padding: 0px;
    }
  }

  .__table__cell__tow {
    display: flex;
    justify-content: center;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }
}


